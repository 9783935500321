<div class="loginwrapper">
  <div class="loginwrap">
    <figure id="loginlogo" class="main-logo-with-dropdwon">
      <img src="assets/images/loginlogo.png" alt="Logo">
      <mat-form-field appearance="fill" class="angular-material-dropdown">
        <mat-select (selectionChange)="langChange()" [(ngModel)]="language">
          <mat-option select value="nl">NL</mat-option>
          <mat-option value="fr">FR</mat-option>
        </mat-select>
      </mat-form-field>
    </figure>

    <form #loginForm="ngForm" name="questionForm" #loginForm="ngForm">
      <div class="logincontent">
        <h3>{{'welcome' | translate}} <span>{{'continue' | translate }}</span></h3>
        <div class="loginformwrapper">
          <div class="form-block">
            <!-- <div class="form-group">
              <input type="email" name="email" [(ngModel)]="email" placeholder="{{'email' | translate}}" required>
            </div> -->
            <!-- <mat-form-field appearance="fill">
              <mat-label>Input</mat-label>
              <input matInput>
            </mat-form-field> -->
            <!-- <div class="form-group">
              <input type="password" name="password" [(ngModel)]="password" placeholder="{{'password' | translate}}" required>
            </div> -->
            <div class="form-group submitwrapper" style="text-align: center">
              <a class="ssobutton" href="https://webadmin.testwecareapp.com/login.php?RelayState=angularcall">
                {{'Login with SSO' | translate}}
              </a>
            </div>
            <div style="text-align: center">
              <a routerLink="/signup" routerLinkActive="active">{{'verify_and_set_password' | translate}}</a>
              <a routerLink="/forgot-password" routerLinkActive="active" style="display: block;">{{'forgot_password' | translate}}?</a>
            </div>
          </div>
        </div>
      </div>
    </form>
    <span *ngIf="isLoginAccess">Login Success</span>
  </div>
</div>
